import React from "react";
import {Typography} from "@material-ui/core";
import {maskCurrency} from "../../../../../utils/commonUtils";

export default function EstimationResultHeader(props: any) {
    const {callingFrom, demo, paymentFrequency, contributionAmount} = props;
    let paymentHeading: any = `Annual Contribution: ${maskCurrency(contributionAmount + "")}`
    if (paymentFrequency === "Monthly") {
        paymentHeading = `Monthly Contribution: ${maskCurrency((contributionAmount/12) + "")}`;
    }
    let title: string = "Quick Estimation Results";
    let text: string = "The results below are from pre-loaded samples. They are meant to help you find a contribution amount that meets your needs with little wait time. Click on the Custom Design to get tailored results.";
    if (demo) {
        text = "This tab allows you to quickly tune and compare designs while you’re still deciding on what you are interested in. It is designed for instant quick results while your still changing numbers around so you don’t have to wait. Quick design does not keep your data and pull it over should you enroll, Custom design will keep your data but takes longer for each run.";
    }
    switch (callingFrom) {
        case 'CUSTOM_DESIGN':
            title = "Custom Design Results";
            text = "When your client estimates their benefits, they will not be able to download a PDF.";
            break;
        case 'ESTIMATOR':
            title = "Estimation Results";
            text = `The results of your custom design are below. Click on each tab to review various aspects of your proposal. To the right, you have the option to edit your design or start from step 1 with a new design. If you like the results you see here, you can save them for your application by clicking "Continue to Enrollment".`
            break;
    }
    return (<>
        <Typography variant="h3" align="left" className="mt45">{title}</Typography>
        <Typography variant="h5" align="left">{paymentHeading}</Typography>
        {paymentFrequency === "Monthly" && <Typography variant="body1">For monthly designs, two months of payments are due at signing.</Typography> }
        <Typography variant="body1">
            {text}
        </Typography>
    </>)
}